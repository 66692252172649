import { Box, styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import backgroundImg from "../Assests/HallOfFame/backgroundImg.svg";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import ShareIcon from "@mui/icons-material/Share";
import axios from "axios";
import { profileImage } from "../redux/features/dashboard/profile_imageSlice";
import { useDispatch } from "react-redux";
import UserImage from "../Assests/Image/Team_Icon.svg";

function HallOfFame() {
  const [data, setData] = useState([]);
  const dispatch = useDispatch();

  const basUrl = `${process.env.REACT_APP_CMS_PROTOCOL}${process.env.REACT_APP_CMS_HOST}${process.env.REACT_APP_CMS_PORT}`;

  const fetchPlayerImage = async (username) => {
    const image = await dispatch(profileImage(username));
    return image?.payload?.image;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${basUrl}/api/fame`);
        const fameData = response.data?.data;
        setData(fameData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [basUrl, dispatch]);

  const StyledUsername = styled(Box)(({ theme }) => ({
    position: "absolute",
    bottom: "16%",
    left: "50%",
    transform: "translateX(-50%)",
    color: "white",
    fontSize: "1.2rem",
    fontFamily: "Poppins, sans-serif",
    transition: "transform 0.5s ease, opacity 0.8s ease",
    opacity: 0,
    zIndex: 3,
  }));

  const shineEffect = {
    position: "relative",
    overflow: "hidden",
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
    "&:before": {
      content: '""',
      position: "absolute",
      top: 0,
      left: "-75%",
      width: "50%",
      height: "100%",
      background:
        "linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%)",
      transform: "skewX(-25deg)",
      transition: "left 0.75s",
      zIndex: 2,
    },
    "&:hover:before": {
      left: "125%",
    },
    "&:hover": {
      "& .background-overlay": {
        opacity: 1, // Show the background overlay
      },
      "& .username": {
        opacity: 1, // Show the username on hover
        transform: "translate(-50%, -50%)", // Move username up
      },
    },
  };

  const backgroundOverlayStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    opacity: 0,
    transition: "opacity 0.5s ease",
    zIndex: 1, // Ensure it's below the shine effect
  };

  return (
    <Box>
      {data.length > 0 ? (
        data.map((team, index) => (
          <Box
            sx={{
              backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.1)), url(${backgroundImg})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center",
              padding: "2.3rem 1.3rem",
            }}
            key={index}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: "2rem",
              }}
            >
              <Box sx={{ flexGrow: 1 }}>
                <Box
                  sx={{
                    background:
                      "linear-gradient(180deg, #D1913C -17.78%, #FFD194 120%)",
                    WebkitBackgroundClip: "text",
                    backgroundClip: "text",
                    color: "transparent",
                    fontFamily: "Squada One, sans-serif",
                    fontWeight: 400,
                    fontSize: { xs: "1.5rem", sm: "3.6rem", md: "4.8rem" },
                    lineHeight: { xs: "1.5rem", sm: "4.5rem", md: "4.5rem" },
                    textAlign: "center",
                  }}
                >
                  {`HALL OF FAME ${team.currentMonth}`}
                </Box>
              </Box>
              <Box sx={{ display: "flex", gap: 2 }}>
                <Box>
                  <a
                    href={team.videoUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: "none" }}
                  >
                    <PlayCircleIcon
                      sx={{
                        background:
                          "linear-gradient(180deg, #D1913C -17.78%, #FFD194 120%)",
                        cursor: "pointer",
                        borderRadius: "20rem",
                        height: "3.2rem",
                        width: "3.2rem",
                      }}
                    />
                  </a>
                </Box>

                <Box
                  sx={{
                    background:
                      "linear-gradient(180deg, #D1913C -17.78%, #FFD194 120%)",
                    cursor: "pointer",
                    borderRadius: "1rem",
                  }}
                >
                  <ShareIcon
                    sx={{
                      height: "2.8rem",
                      width: "3.2rem",
                    }}
                  />
                </Box>
              </Box>
            </Box>

            {/* Team and Stats Section */}
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                gap: "2rem",
                width: "100%",
              }}
            >
              {/* Players Section */}
              <Box
                sx={{
                  display: "flex",
                  gap: "1rem",
                  justifyContent: {
                    xs: "space-evenly",
                    sm: "space-evenly",
                    md: "flex-start",
                  },
                }}
              >
                {team.teamUsernames.map((player, idx) => (
                  <Box
                    key={idx}
                    sx={{
                      ...shineEffect,
                      width: { xs: "16rem", sm: "10rem", md: "12rem" },
                      height: { xs: "22rem", sm: "25rem", md: "30rem" },
                      borderRadius: "1rem",
                      position: "relative",
                    }}
                  >
                    <Box
                      component="img"
                      src={
                        player?.image
                          ? `data:image/png;base64,${player?.image}`
                          : UserImage
                      }
                      alt={player.username}
                      sx={{
                        width: "100%",
                        height: "100%",
                        borderRadius: "1rem",
                        objectFit: "cover",
                      }}
                    />
                    <Box
                      sx={backgroundOverlayStyle}
                      className="background-overlay"
                    />
                    <StyledUsername className="username">
                      {player.username}
                    </StyledUsername>
                  </Box>
                ))}
              </Box>

              {/* Team Info Section */}
              <Box>
                <Box
                  sx={{
                    background:
                      "linear-gradient(180deg, #966525 100%, #D39B51 100%)",
                    WebkitBackgroundClip: "text",
                    backgroundClip: "text",
                    color: "transparent",
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: 700,
                    fontSize: { xs: "1.6rem", sm: "2.4rem", md: "2.4rem" },
                    lineHeight: { xs: "4.5rem", sm: "2.4rem", md: "4.5rem" },
                    textAlign: "center",
                  }}
                >
                  Team:{" "}
                  <span
                    style={{
                      color: "white",
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: 700,
                    }}
                  >
                    {team.teamName}
                  </span>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "space-around",
                    gap: "2rem",
                  }}
                >
                  {/* Most Played */}
                  <Box sx={{ textAlign: "center" }}>
                    <Box
                      sx={{
                        fontFamily: "Poppins, sans-serif",
                        fontWeight: 700,
                        fontSize: { xs: "4.8rem", sm: "8.2rem", md: "9.6rem" },
                        lineHeight: {
                          xs: "4.8rem",
                          sm: "8.2rem",
                          md: "9.6rem",
                        },
                        background:
                          "linear-gradient(181.74deg, #D39440 23.97%, rgba(246, 196, 130, 0) 82.81%)",
                        WebkitBackgroundClip: "text",
                        backgroundClip: "text",
                        color: "transparent",
                      }}
                    >
                      {team.matchesPlayed}
                    </Box>
                    <Box
                      sx={{
                        color: "#EFEFEF",
                        fontFamily: "Poppins, sans-serif",
                        fontWeight: 700,
                        fontSize: { xs: "0.8rem", sm: "1.4rem", md: "1.6rem" },
                        lineHeight: {
                          xs: "1.2rem",
                          sm: "2.1rem",
                          md: "2.4rem",
                        },
                        marginTop: "-1rem",
                      }}
                    >
                      Matches Played
                    </Box>
                  </Box>

                  {/* Matches Won */}
                  <Box sx={{ textAlign: "center" }}>
                    <Box
                      sx={{
                        fontFamily: "Poppins, sans-serif",
                        fontWeight: 700,
                        fontSize: { xs: "4.8rem", sm: "8.2rem", md: "9.6rem" },
                        lineHeight: {
                          xs: "4.8rem",
                          sm: "8.2rem",
                          md: "9.6rem",
                        },
                        background:
                          "linear-gradient(181.74deg, #D39440 23.97%, rgba(246, 196, 130, 0) 82.81%)",
                        WebkitBackgroundClip: "text",
                        backgroundClip: "text",
                        color: "transparent",
                      }}
                    >
                      {team.matchesWon}
                    </Box>
                    <Box
                      sx={{
                        color: "#EFEFEF",
                        fontFamily: "Poppins, sans-serif",
                        fontWeight: 700,
                        fontSize: { xs: "0.8rem", sm: "1.4rem", md: "1.6rem" },
                        lineHeight: {
                          xs: "1.2rem",
                          sm: "2.1rem",
                          md: "2.4rem",
                        },
                        marginTop: "-1rem",
                      }}
                    >
                      Matches Won
                    </Box>
                  </Box>

                  {/* XP Earned */}
                  <Box sx={{ textAlign: "center" }}>
                    <Box
                      sx={{
                        fontFamily: "Poppins, sans-serif",
                        fontWeight: 700,
                        fontSize: { xs: "4.8rem", sm: "8.2rem", md: "9.6rem" },
                        lineHeight: {
                          xs: "4.8rem",
                          sm: "8.2rem",
                          md: "9.6rem",
                        },
                        background:
                          "linear-gradient(181.74deg, #D39440 23.97%, rgba(246, 196, 130, 0) 82.81%)",
                        WebkitBackgroundClip: "text",
                        backgroundClip: "text",
                        color: "transparent",
                      }}
                    >
                      {team.xpEarned}
                    </Box>
                    <Box
                      sx={{
                        color: "#EFEFEF",
                        fontFamily: "Poppins, sans-serif",
                        fontWeight: 700,
                        fontSize: { xs: "0.8rem", sm: "1.4rem", md: "1.6rem" },
                        lineHeight: {
                          xs: "1.2rem",
                          sm: "2.1rem",
                          md: "2.4rem",
                        },
                        marginTop: "-1rem",
                      }}
                    >
                      XP Earned
                    </Box>
                  </Box>

                  {/* Rewards Earned */}
                  <Box sx={{ textAlign: "center" }}>
                    <Box
                      sx={{
                        fontFamily: "Poppins, sans-serif",
                        fontWeight: 700,
                        fontSize: { xs: "4.8rem", sm: "8.2rem", md: "9.6rem" },
                        lineHeight: {
                          xs: "4.8rem",
                          sm: "8.2rem",
                          md: "9.6rem",
                        },
                        background:
                          "linear-gradient(181.74deg, #D39440 23.97%, rgba(246, 196, 130, 0) 82.81%)",
                        WebkitBackgroundClip: "text",
                        backgroundClip: "text",
                        color: "transparent",
                      }}
                    >
                      {team.rewardWon}
                    </Box>
                    <Box
                      sx={{
                        color: "#EFEFEF",
                        fontFamily: "Poppins, sans-serif",
                        fontWeight: 700,
                        fontSize: { xs: "0.8rem", sm: "1.4rem", md: "1.6rem" },
                        lineHeight: {
                          xs: "1.2rem",
                          sm: "2.1rem",
                          md: "2.4rem",
                        },
                        marginTop: "-1rem",
                      }}
                    >
                      Rewards Earned
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        ))
      ) : (
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              fontFamily: "Poppins, sans-serif",
              fontWeight: 700,
              fontSize: { xs: "1.6rem", sm: "2.4rem", md: "4rem" },
              lineHeight: { xs: "2.4rem", sm: "3.6rem", md: "6rem" },
              color: "#505658",
            }}
          >
            “The journey has just begun...”
          </Box>
          <Box
            sx={{
              fontFamily: "Poppins, sans-serif",
              fontWeight: 400,
              fontSize: { xs: "1.6rem", sm: "2.4rem", md: "4rem" },
              lineHeight: { xs: "2.4rem", sm: "3.6rem", md: "6rem" },
              color: "#525252",
            }}
          >
            Our champions will be here soon
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default HallOfFame;
