import React, { useEffect, useState } from "react";
import { Alert, Box, Snackbar } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import bgImage from "../Assests/signIn/bgImage.svg";
import CustomInput from "../shared/CustomInput/CustomInput";
import CustomPassword from "../shared/CustomPassword/CustomPassword";
import { loginUser } from "../redux/features/login/loginSlice";
import Button from "../shared/Button/Button";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";

const styles = {
  container: {
    width: "100%",
    minHeight: "100vh",
    height: "100%",
    backgroundImage: `url(${bgImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  formContainer: {
    width: "fit-content",
    background: "#F3F7EC",
    borderRadius: "2.2rem",
    border: "0.2rem solid #36769D",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "3rem  9rem",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
  },
  title: {
    fontFamily: "Poppins, sans-serif",
    fontWeight: 700,
    fontSize: { xs: "2rem", sm: "3.6rem", md: "4.8rem" },
    lineHeight: { xs: "3rem", sm: "5.4rem", md: "7.2rem" },
    textAlign: "center",
    marginBottom: "2rem",
  },
  checkboxLabel: {
    fontFamily: "Poppins, sans-serif",
    fontWeight: 400,
    fontSize: { xs: "1rem", sm: "1.4rem", md: "1.6rem" },
    lineHeight: { xs: "1.5rem", sm: "2.1rem", md: "2.4rem" },
    color: "#3B3755",
    alignContent: "center",
  },
  footerText: {
    fontFamily: "Poppins, sans-serif",
    fontWeight: 400,
    fontSize: "1.6rem",
    lineHeight: "2.4rem",
    color: "#3B3755",
  },
};

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formValues, setFormValues] = useState({
    email: "",
    password: "",
  });
  const [open, setOpen] = useState(false);
  const { user, error } = useSelector((state) => state.login);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
      [name]: name === "email" ? value.toLowerCase() : value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(loginUser(formValues));
    setFormValues({
      email: "",
      password: "",
    });
  };

  useEffect(() => {
    if (user) {
      navigate("/dashboard");
    }
    if (error) {
      setOpen(true);
    }
  }, [user, error, navigate]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <Box sx={styles.container}>
      <Box sx={styles.formContainer}>
        <Box sx={{ display: "flex", width: "100%" }}>
          <Box
            sx={{
              width: "5rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Link to="/">
              <KeyboardDoubleArrowLeftIcon
                sx={{
                  width: "5rem",
                  height: { xs: "2.5rem", sm: "4.9rem" },
                  color: "#423F63",
                }}
              />
            </Link>
          </Box>
          <Box
            sx={{
              justifyContent: "flex-end",
              margin: "0 auto",
              ...styles.title,
            }}
          >
            Login
          </Box>
        </Box>
        {error && (
          <Snackbar
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
          >
            <Alert
              onClose={handleClose}
              severity="error"
              variant="filled"
              sx={{ width: "100%", fontSize: "1.4rem" }}
            >
              {error.message}
            </Alert>
          </Snackbar>
        )}
        <form onSubmit={handleSubmit} style={{ width: "100%" }}>
          <CustomInput
            name="email"
            value={formValues.email}
            onChange={handleChange}
            placeholder="Email"
            required
          />
          <CustomPassword
            name="password"
            value={formValues.password}
            onChange={handleChange}
            placeholder="Password"
            required
          />
          <Button text="Login" type="submit" />
        </form>
        <Box sx={{ ...styles.footerText, margin: { xs: 0.5, sm: 0.7, md: 1 } }}>
          Don’t have an account?{" "}
          <Link to="/signUp">
            {" "}
            <span style={{ fontWeight: 700 }}>Register</span>
          </Link>
        </Box>
      </Box>
    </Box>
  );
}

export default Login;
