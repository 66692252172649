import React, { useState } from "react";
import { Menu, Sidebar, MenuItem, useProSidebar } from "react-pro-sidebar";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PersonIcon from "@mui/icons-material/Person";
import CloseOutlinedIcon from "@mui/icons-material/West";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import LiveTvIcon from "@mui/icons-material/LiveTv";
import HistoryIcon from "@mui/icons-material/History";
import Logo from "../../../Assests/Logo/Logo.svg";
import { logout } from "../../../services/auth";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useSelector } from "react-redux";
import ConnectWithoutContactIcon from "@mui/icons-material/ConnectWithoutContact";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import LogoutIcon from "@mui/icons-material/Logout";
import InfoIcon from "@mui/icons-material/Info";

const Item = ({
  title,
  to,
  icon,
  selected,
  setSelected,
  isActive,
  onClick,
}) => {
  return (
    <MenuItem
      className={isActive ? "active" : ""}
      onClick={() => {
        setSelected(title);
        if (onClick) onClick();
      }}
      icon={
        <Box sx={{ width: "3rem", height: "3rem" }}>
          {React.cloneElement(icon, { sx: { fontSize: "2.5rem" } })}
        </Box>
      }
      routerLink={<Link to={to} />}
    >
      <Typography
        sx={{
          fontFamily: "Poppins, sans-serif",
          fontWeight: 500,
          fontSize: "1.5rem",
          lineHeight: "2.7rem",
          color: "white",
        }}
      >
        {title}
      </Typography>
    </MenuItem>
  );
};

const UserProfile = ({ title, icon, selected, setSelected }) => {
  return (
    <MenuItem
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        gap: "1rem",
      }}
      className={selected === title ? "active" : ""}
      onClick={() => setSelected(title)}
      icon={
        <Box
          component="img"
          src={icon}
          alt={title}
          sx={{
            width: "6rem",
            height: "6rem",
            borderRadius: "50%",
            objectFit: "cover",
          }}
        />
      }
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Inter, sans-serif",
            fontWeight: 600,
            fontSize: "1.6rem",
            lineHeight: "2rem",
            color: "white",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {title}
        </Typography>
      </Box>
    </MenuItem>
  );
};

const FriendsList = ({ friendName, icon, selected, setSelected }) => {
  return (
    <MenuItem
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        gap: "0.5rem",
      }}
      className={selected === friendName ? "active" : ""}
      onClick={() => setSelected(friendName)}
      icon={
        <Box
          component="img"
          src={`data:image/jpeg;base64,${icon}`}
          alt={friendName}
          sx={{
            width: "4.5rem",
            height: "4.5rem",
            borderRadius: "50%",
            objectFit: "cover",
          }}
        />
      }
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Inter, sans-serif",
            fontWeight: 600,
            fontSize: "1.6rem",
            lineHeight: "2rem",
            color: "white",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {friendName}
        </Typography>
      </Box>
    </MenuItem>
  );
};

const Logout = ({ title, to, icon, selected, setSelected, onClick }) => {
  return (
    <MenuItem
      style={{ background: "red" }}
      className={selected === title ? "active" : ""}
      onClick={onClick}
      icon={
        <Box sx={{ width: "3rem", height: "3rem" }}>
          {React.cloneElement(icon, { sx: { fontSize: "2.5rem" } })}
        </Box>
      }
      routerLink={<Link to={to} />}
    >
      <Typography
        sx={{
          fontFamily: "Poppins, sans-serif",
          fontWeight: 500,
          fontSize: "1.5rem",
          lineHeight: "2.7rem",
          color: "white",
        }}
      >
        {title}
      </Typography>
    </MenuItem>
  );
};

const MyProSidebar = ({ collapsed, toggleSidebars }) => {
  const { toggleSidebar, broken, rtl } = useProSidebar();
  const { user } = useSelector((state) => state.dashboard);
  const { allFrienduser } = useSelector((state) => state.allFriend);
  const userName = user?.first_name + " " + user?.last_name || [];

  const [selected, setSelected] = useState("Dashboard");
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const location = useLocation();
  const path = location.pathname;

  const { profileImageuser } = useSelector((state) => state.profileImage);
  const imageSrc = profileImageuser
    ? `data:image/png;base64,${profileImageuser?.image}`
    : null;

  return (
    <Box
      sx={{
        position: "sticky",
        display: "flex",
        height: "100vh",
        top: 0,
        bottom: 0,
        zIndex: 10000,
        "& .menu-icon": {
          color: "#BFBFBF",
          backgroundColor: "transparent !important",
        },
        "& .menu-anchor": {
          color: "yellow",
          backgroundColor: "transparent !important",
        },
        "& .menu-item:hover": {
          color: "white",
          backgroundColor: "#181818",
        },
        "& .menu-item.active": {
          color: "white",
          backgroundColor: "#686DE0",
          borderRadius: "1.5rem",
        },
      }}
    >
      <Sidebar
        breakPoint="md"
        backgroundColor={""}
        collapsed={collapsed}
        style={{ borderRight: "0.5px solid #454545", background: "#242627" }}
      >
        <Box
          sx={{
            height: "6rem",
            width: "100%",
            display: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={Logo}
            alt="Logo"
            style={{ height: "6rem", width: "8rem" }}
          />
        </Box>
        <Menu iconshape="square">
          {!isSmallScreen && (
            <MenuItem
              icon={
                collapsed ? (
                  <MenuOutlinedIcon onClick={toggleSidebars} />
                ) : (
                  <IconButton onClick={toggleSidebars}>
                    <CloseOutlinedIcon sx={{ color: "white" }} />
                  </IconButton>
                )
              }
              style={{
                margin: "0px",
              }}
            />
          )}
          {isSmallScreen ? (
            <>
              <Box sx={{ background: "#535C68", borderRadius: "0.8rem" }}>
                <Accordion sx={{ background: "#535C68" }}>
                  <AccordionSummary
                    expandIcon={<KeyboardArrowDownIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    sx={{
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: 700,
                      fontSize: "1.5rem",
                      lineHeight: "2.2rem",
                      color: "white",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    MENU
                  </AccordionSummary>
                  <AccordionDetails sx={{ m: 0, p: 0 }}>
                    <Item
                      title="Home"
                      to="/dashboard"
                      icon={<HomeOutlinedIcon />}
                      selected={selected}
                      setSelected={setSelected}
                      isActive={path === "/dashboard"}
                      onClick={isSmallScreen ? toggleSidebar : undefined}
                    />
                    <Item
                      title="Profile"
                      to="/profile"
                      icon={<PersonIcon />}
                      selected={selected}
                      setSelected={setSelected}
                      isActive={path === "/profile"}
                      onClick={isSmallScreen ? toggleSidebar : undefined}
                    />
                    <Item
                      title="Tournaments"
                      to="/tournaments"
                      icon={<EmojiEventsIcon />}
                      selected={selected}
                      setSelected={setSelected}
                      isActive={path === "/tournaments"}
                      onClick={isSmallScreen ? toggleSidebar : undefined}
                    />
                    <Item
                      title="Recent & Livestreams"
                      to="/recent&Livestreams"
                      icon={<LiveTvIcon />}
                      selected={selected}
                      setSelected={setSelected}
                      isActive={path === "/recent&Livestreams"}
                      onClick={isSmallScreen ? toggleSidebar : undefined}
                    />
                    <Item
                      title="Community"
                      to="/community"
                      icon={<ConnectWithoutContactIcon />}
                      selected={selected}
                      setSelected={setSelected}
                      isActive={path === "/community"}
                      onClick={isSmallScreen ? toggleSidebar : undefined}
                    />
                    <Item
                      title="Hall of Fame"
                      to="/halloffame"
                      icon={<EmojiEventsIcon />}
                      selected={selected}
                      setSelected={setSelected}
                      isActive={path === "/halloffame"}
                      onClick={isSmallScreen ? toggleSidebar : undefined}
                    />
                    <Item
                      title="UpComing Matches"
                      to="/history"
                      icon={<HistoryIcon />}
                      selected={selected}
                      setSelected={setSelected}
                      isActive={path === "/history"}
                      onClick={isSmallScreen ? toggleSidebar : undefined}
                    />
                    <Item
                      title="Support"
                      to="/support"
                      icon={<SupportAgentIcon />}
                      selected={selected}
                      setSelected={setSelected}
                      isActive={path === "/support"}
                      onClick={isSmallScreen ? toggleSidebar : undefined}
                    />
                    <Item
                      title="About Us"
                      to="/aboutUs"
                      icon={<InfoIcon />}
                      selected={selected}
                      setSelected={setSelected}
                      isActive={path === "/aboutUs"}
                      onClick={isSmallScreen ? toggleSidebar : undefined}
                    />
                    <hr />
                    <Logout
                      title="Logout"
                      to="/"
                      icon={<LogoutIcon />}
                      selected={selected}
                      setSelected={setSelected}
                      onClick={logout}
                    />
                  </AccordionDetails>
                </Accordion>
              </Box>
              <Box sx={{ borderRadius: "0.8rem" }}>
                <Box sx={{ padding: "1rem 0.5rem" }}>
                  <UserProfile
                    title={userName}
                    icon={imageSrc}
                    selected={selected}
                    setSelected={setSelected}
                  />
                </Box>
              </Box>
              <Box sx={{ background: "#535C68", borderRadius: "0.8rem" }}>
                <Accordion sx={{ background: "#535C68" }}>
                  <AccordionSummary
                    expandIcon={<KeyboardArrowDownIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    sx={{
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: 700,
                      fontSize: "1.5rem",
                      lineHeight: "2.2rem",
                      color: "white",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    Social
                  </AccordionSummary>
                  <AccordionDetails sx={{ m: 0, p: 0 }}>
                    {allFrienduser?.map((friend, idx) => (
                      <FriendsList
                        key={idx}
                        friendName={friend?.username}
                        icon={friend?.user_profile_picture}
                        selected={selected}
                        setSelected={setSelected}
                      />
                    ))}
                  </AccordionDetails>
                </Accordion>
              </Box>
            </>
          ) : (
            <Box>
              <Item
                title="Home"
                to="/dashboard"
                icon={<HomeOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
                isActive={path === "/dashboard"}
              />
              <Item
                title="Profile"
                to="/profile"
                icon={<PersonIcon />}
                selected={selected}
                setSelected={setSelected}
                isActive={path === "/profile"}
              />
              <Item
                title="Tournaments"
                to="/tournaments"
                icon={<EmojiEventsIcon />}
                selected={selected}
                setSelected={setSelected}
                isActive={path === "/tournaments"}
              />
              <Item
                title="Recent & Livestreams"
                to="/recent&Livestreams"
                icon={<LiveTvIcon />}
                selected={selected}
                setSelected={setSelected}
                isActive={path === "/recent&Livestreams"}
              />
              <Item
                title="Community"
                to="/community"
                icon={<ConnectWithoutContactIcon />}
                selected={selected}
                setSelected={setSelected}
                isActive={path === "/community"}
              />
              <Item
                title="Hall of Fame"
                to="/halloffame"
                icon={<EmojiEventsIcon />}
                selected={selected}
                setSelected={setSelected}
                isActive={path === "/halloffame"}
              />
              <Item
                title="UpComing Matches"
                to="/history"
                icon={<HistoryIcon />}
                selected={selected}
                setSelected={setSelected}
                isActive={path === "/history"}
              />
              <Item
                title="Support"
                to="/support"
                icon={<SupportAgentIcon />}
                selected={selected}
                setSelected={setSelected}
                isActive={path === "/support"}
              />
              <Item
                title="About Us"
                to="/aboutUs"
                icon={<InfoIcon />}
                selected={selected}
                setSelected={setSelected}
                isActive={path === "/aboutUs"}
              />
              <hr />
              <Logout
                title="Logout"
                to="/"
                icon={<LogoutIcon />}
                selected={selected}
                setSelected={setSelected}
                onClick={logout}
              />
            </Box>
          )}
        </Menu>
      </Sidebar>
    </Box>
  );
};

export default MyProSidebar;
