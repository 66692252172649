import { Box, Divider } from "@mui/material";
import React from "react";
import Logo from "../Assests/Logo/Logo.svg";
import Twitter from "../Assests/Logo/Twitter.svg";
import Facebook from "../Assests/Logo/Facebook.svg";
import Instagram from "../Assests/Logo/Instagram.svg";
import Twitch from "../Assests/Logo/twitch.svg";
import Youtube from "../Assests/Logo/youtube.svg";
import ToTheTop from "../Assests/Logo/ToTheTop.svg";
import "../style/fontStyle.css";

function Footer() {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <Box
      sx={{
        backgroundColor: "#171717",
        padding: {
          xs: "2rem",
          sm: "2rem 5rem",
          md: "2rem 8rem",
          lg: "2rem 11rem",
        },
      }}
    >
      <Box
        className="Footertext"
        sx={{ color: "white", display: "flex", justifyContent: "center" }}
      >
        <Box>
          <Box sx={{ textAlign: "center" }} >
            Beta Version Announcement: "This is our beta version!" <br /> Signup
            Incentive: "Sign up now to receive"
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <ul>
              <li>200 Battle Coins</li>
              <li>A free level up to Level 5</li>
            </ul>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          justifyContent: "space-between",
          alignItems: { xs: "center", md: "end" },
          paddingBottom: "2rem",
          gap: { xs: "2rem", md: "0" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "2rem",
            alignItems: "end",
          }}
        >
          <img
            src={Logo}
            alt="Logo"
            style={{ height: "4rem", width: "8rem" }}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            gap: { xs: "2rem", sm: "3rem", md: "3rem" },
            color: "white",
            flexDirection: { xs: "column", md: "row" },
            alignItems: { xs: "center", md: "end" },
          }}
        >
          <Box className="Footertext">Blogs</Box>
          <Box className="Footertext">Live Streams</Box>
          <Box className="Footertext">Tournaments</Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            gap: "2rem",
            color: "white",
            justifyContent: "center",
          }}
        >
          <a href="eampleLink">
            <img
              src={Twitter}
              alt="Twitter"
              style={{ height: "2rem", width: "2rem" }}
            />
          </a>
          <a href="eampleLink">
            <img
              src={Facebook}
              alt="Facebook"
              style={{ height: "2rem", width: "2rem" }}
            />
          </a>
          <a href="eampleLink">
            <img
              src={Instagram}
              alt="Instagram"
              style={{ height: "2rem", width: "2rem" }}
            />
          </a>
          <a href="eampleLink">
            <img
              src={Twitch}
              alt="Twitch"
              style={{ height: "2rem", width: "2rem" }}
            />
          </a>
          <a href="eampleLink">
            <img
              src={Youtube}
              alt="Youtube"
              style={{ height: "2rem", width: "2rem" }}
            />
          </a>
        </Box>
      </Box>
      <Divider sx={{ borderColor: "white" }} />

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: { xs: "column", md: "row" },
          alignItems: { xs: "center", md: "stretch" },
          gap: { xs: "1rem", md: "0" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "1rem",
            flexDirection: { xs: "column", md: "row" },
            alignItems: { xs: "center", md: "stretch" },
          }}
        >
          <Box
            sx={{
              color: "white",
              fontFamily: "Poppins, sans-serif",
              fontWeight: 400,
              fontSize: { xs: "1rem", md: "1.4rem" },
              lineHeight: "4.5rem",
              textAlign: "left",
            }}
          >
            COOKIE PREFERENCES
          </Box>
          <Divider
            orientation="vertical"
            flexItem
            sx={{
              borderColor: "white",
              display: { xs: "none", md: "block" },
              margin: "1rem 0rem",
            }}
          />
          <Box
            sx={{
              color: "white",
              fontFamily: "Poppins, sans-serif",
              fontWeight: 400,
              fontSize: { xs: "1rem", md: "1.4rem" },
              lineHeight: "4.5rem",
              textAlign: "left",
            }}
          >
            ©️ BattleSync All Rights Reserved.
          </Box>
        </Box>
        <Box sx={{ display: "flex", gap: "1rem", alignItems: "center" }}>
          <Box
            sx={{
              color: "white",
              fontFamily: "Squada One, sans-serif",
              fontWeight: 400,
              fontSize: { xs: "1.5rem", md: "2.0rem" },
              lineHeight: "4.5rem",
              textAlign: "center",
            }}
            onClick={scrollToTop}
          >
            TO THE TOP
          </Box>
          <Box onClick={scrollToTop}>
            <img
              src={ToTheTop}
              alt="To The Top"
              style={{ height: "2rem", width: "2rem" }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Footer;
