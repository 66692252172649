import React, { useState, useCallback, useEffect } from "react";
import {
  Box,
  IconButton,
  InputBase,
  Typography,
  Snackbar,
  Alert,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { useDispatch, useSelector } from "react-redux";
import { searchFriend } from "../redux/features/friends/friendSlice";
import { requestFriend } from "../redux/features/friends/requestSlice";
import { allFriend } from "../redux/features/friends/allFriendsSlice";
import { pendingRequest } from "../redux/features/friends/pendingRequestSlice";
import FriendsCard from "../shared/FriendsCard/FriendsCard";
import InternalButton from "../shared/Button/InternalButton";
import { acceptendingRequest } from "../redux/features/friends/acceptPendingRequest";
import { RejectPendingRequest } from "../redux/features/friends/RejectPendingRequest";

function FriendAndGroups() {
  const [username, setUsername] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [registerTeam, setRegisterTeam] = useState("");

  const dispatch = useDispatch();
  const { searchFrienduser } = useSelector((state) => state.searchFriend);
  const { user } = useSelector((state) => state.dashboard);
  const { allFrienduser } = useSelector((state) => state.allFriend);
  const { pendingRequestuser } = useSelector((state) => state.pendingRequest);
  const [friendImages, setFriendImages] = useState([]);
  const [pendingRequestImages, setPendingRequestImages] = useState([]);
  const [searchprofilePicture, setsearchProfilePicture] = useState(null);
  const [selectedFriends, setSelectedFriends] = useState([]);

  const basUrl = `${process.env.REACT_APP_PROTOCOL}${process.env.REACT_APP_HOST}${process.env.REACT_APP_PORT}`;

  const handleSearch = async () => {
    if (username.trim()) {
      try {
        await dispatch(
          searchFriend({ userid_1: user?.username, userid_2: username })
        );
        // Fetch the image after searching
        const image = await fetchsearchImage(username);
        setsearchProfilePicture(image);
      } catch (error) {
        console.error("Search failed. Please try again.", error);
      }
    } else {
      console.warn("Please enter a username to search.");
    }
  };

  const handleUser = (friend) => {
    setSelectedFriends((prevSelected) =>
      prevSelected.includes(friend)
        ? prevSelected.filter((item) => item !== friend)
        : [...prevSelected, friend]
    );
  };

  const handleTeamName = async () => {
    if (!registerTeam.trim()) {
      alert("Please enter a team name.");
      return;
    }

    try {
      // Step 1: Create the team
      const response = await fetch(`${basUrl}/teams`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          name: registerTeam,
          owner_username: user?.username,
        }),
      });
      const result = await response.json();

      if (response.ok) {
        alert("Team created successfully!");
        const teamId = result.id; // Extract the team ID

        // Step 2: Add players to the team one by one
        for (const player of selectedFriends) {
          try {
            const addPlayerResponse = await fetch(
              `${basUrl}/teams/${teamId}/players`,
              {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                  player_username: player,
                }),
              }
            );
            const addPlayerResult = await addPlayerResponse.json();

            if (!addPlayerResponse.ok) {
              console.error(
                `Failed to add player ${player}: ${addPlayerResult.message}`
              );
            }
          } catch (error) {
            console.error(`Error adding player ${player}:`, error);
          }
        }
      } else {
        alert(`Error creating team: ${result.message}`);
      }
    } catch (error) {
      console.error("Error creating team:", error);
      alert("Error creating team.");
    }
  };

  const fetchAllFriends = useCallback(() => {
    if (user) {
      dispatch(allFriend({ username: user?.username }));
    }
  }, [user, dispatch]);

  const fetchPendingRequests = useCallback(() => {
    if (user) {
      dispatch(pendingRequest({ username: user?.username }));
    }
  }, [user, dispatch]);

  useEffect(() => {
    fetchAllFriends();
    fetchPendingRequests();
  }, [fetchAllFriends, fetchPendingRequests]);

  const hasUsers = searchFrienduser?.userData?.length > 0;
  const noKeywordEntered = !username.trim();

  const handleUsername = (user2id) => {
    dispatch(requestFriend({ userid_1: user?.username, userid_2: user2id }))
      .then(() => {
        setSnackbarMessage("Friend request sent successfully!");
        setSnackbarSeverity("success");
        dispatch(
          searchFriend({ userid_1: user?.username, userid_2: username })
        );
      })
      .catch(() => {
        setSnackbarMessage("Failed to send friend request.");
        setSnackbarSeverity("error");
      })
      .finally(() => setSnackbarOpen(true));
  };

  const handleAcceptRequest = (user2id) => {
    dispatch(
      acceptendingRequest({ username: user?.username, user2id, status: true })
    )
      .then(() => {
        setSnackbarMessage("Friend request accepted!");
        setSnackbarSeverity("success");
        fetchAllFriends(); // Update the friends list after accepting the request
        fetchPendingRequests(); // Update the pending requests list
      })
      .catch(() => {
        setSnackbarMessage("Failed to accept friend request.");
        setSnackbarSeverity("error");
      })
      .finally(() => setSnackbarOpen(true));
  };

  const handleRemoveRequest = (user2id) => {
    dispatch(RejectPendingRequest({ username: user?.username, user2id }))
      .then(() => {
        setSnackbarMessage("Friend request removed.");
        setSnackbarSeverity("info");
        fetchPendingRequests(); // Update the pending requests list after removing the request
      })
      .catch(() => {
        setSnackbarMessage("Failed to remove friend request.");
        setSnackbarSeverity("error");
      })
      .finally(() => setSnackbarOpen(true));
  };

  const onRemoveFriend = (user2id) => {
    dispatch(allFriend({ username: user?.username, user2id }))
      .then(() => {
        setSnackbarMessage("Friend removed successfully.");
        setSnackbarSeverity("success");
        fetchAllFriends(); // Update the friends list after removing the friend
      })
      .catch(() => {
        setSnackbarMessage("Failed to remove friend.");
        setSnackbarSeverity("error");
      })
      .finally(() => setSnackbarOpen(true));
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  // Dynamic function to fetch profile images
  const fetchImages = async (userList) => {
    const updatedFriends = await Promise.all(
      userList.map(async (friend) => {
        try {
          const response = await fetch(
            `${basUrl}/profile-picture/${friend.username}`
          );
          const data = await response.json();
          if (response.ok) {
            return {
              name: friend.username,
              icon: `data:image/png;base64,` + data.image,
            };
          } else {
            console.error(`Failed to fetch image for ${friend.username}`);
            return { name: friend.username };
          }
        } catch (error) {
          console.error(`Error fetching image for ${friend.username}:`, error);
          return { name: friend.username };
        }
      })
    );
    return updatedFriends;
  };

  useEffect(() => {
    if (allFrienduser) {
      fetchImages(allFrienduser).then((images) => {
        // Create a mapping of username to image icon
        const imageMap = new Map(images.map(({ name, icon }) => [name, icon]));

        // Add images to friends list
        const updatedFriends = allFrienduser.map((friend) => ({
          ...friend,
          profilePicture:
            imageMap.get(friend.username) || friend.profilePicture,
        }));
        setFriendImages(updatedFriends);
      });
    }
  }, [allFrienduser]);

  useEffect(() => {
    if (pendingRequestuser) {
      fetchImages(pendingRequestuser).then((images) => {
        // Create a mapping of username to image icon
        const imageMap = new Map(images.map(({ name, icon }) => [name, icon]));

        // Add images to pending requests list
        const updatedRequests = pendingRequestuser.map((request) => ({
          ...request,
          profilePicture:
            imageMap.get(request.username) || request.profilePicture,
        }));

        setPendingRequestImages(updatedRequests);
      });
    }
  }, [pendingRequestuser]);

  const fetchsearchImage = async (username) => {
    let retdata = null;
    try {
      const response = await fetch(`${basUrl}/profile-picture/${username}`);
      const data = await response.json();
      if (response.ok) {
        retdata = `data:image/png;base64,${data.image}`;
      } else {
        console.error(`Failed to fetch image for ${username}`);
      }
    } catch (error) {
      console.error("Unable to fetch Image", error);
    }
    return retdata;
  };

  return (
    <Box
      sx={{
        height: "calc(100% - 7.5rem)",
        overflowY: "auto",
        "&::-webkit-scrollbar": {
          width: 6,
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "grey",
          borderRadius: "10px",
        },
        p: 1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: "2rem",
          width: "100%",
          flexDirection: { xs: "column", sm: "row" },
        }}
      >
        <FriendsCard
          title="Friends and Group list"
          allFriendsData={friendImages}
          buttonType="friendList"
          onRemoveFriend={onRemoveFriend}
        />
        <FriendsCard
          title="Pending Requests"
          allFriendsData={pendingRequestImages}
          buttonType="pendingList"
          onAcceptRequest={handleAcceptRequest}
          onRejectRequest={handleRemoveRequest}
        />
      </Box>
      <Box
        sx={{
          height: "40rem",
          borderRadius: "0.8rem",
          border: "1px solid #3F3F3F",
          margin: "1rem 0rem",
        }}
      >
        <Box
          sx={{
            background: "#686DE0",
            borderRadius: "0.8rem 0.8rem 0rem 0rem",
            padding: "0.4rem 3.4rem",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box sx={{ display: "flex", gap: "2rem" }} flex={1}>
            <Box
              display="flex"
              border="0.5px solid #7F7F7F"
              backgroundColor={"#535C68"}
              borderRadius={2}
              flex={1}
              sx={{ height: { xs: 30, sm: 35, md: 35 } }}
            >
              <IconButton type="button" sx={{ color: "#BFBFBF" }}>
                <SearchIcon />
              </IconButton>
              <Box
                sx={{
                  position: "relative",
                  ml: 1,
                  flex: 1,
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
              >
                <InputBase
                  sx={{
                    flex: 1,
                    width: "100%",
                    color: "#BFBFBF",
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: 500,
                    fontSize: "1.8rem",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
                {!username && (
                  <Box
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      pointerEvents: "none",
                      color: "#BFBFBF",
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: 500,
                      fontSize: "1.8rem",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      alignContent: "center",
                    }}
                  >
                    Search for a friend or Team...
                  </Box>
                )}
              </Box>
            </Box>

            <Box sx={{ alignContent: "center" }}>
              <InternalButton
                text="Search"
                type="submit"
                onClick={handleSearch}
              />
            </Box>
          </Box>
        </Box>
        <Box sx={{ padding: "1rem" }}>
          {noKeywordEntered ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "25vh",
              }}
            >
              <Typography
                variant="h6"
                sx={{ textAlign: "center", color: "#BFBFBF" }}
              >
                Enter a username to search
              </Typography>
            </Box>
          ) : hasUsers ? (
            searchFrienduser.userData.map((data, idx) => (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "1rem",
                  justifyContent: "space-between",
                }}
                key={idx}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box
                    component="img"
                    src={searchprofilePicture}
                    alt="userImg"
                    sx={{
                      width: "6rem",
                      height: "6rem",
                      borderRadius: "50%",
                      objectFit: "cover",
                      display: "block",
                      aspectRatio: "1 / 1",
                    }}
                  />
                  <Box sx={{ marginLeft: "1rem" }}>
                    <Box
                      sx={{
                        fontFamily: "Poppins, sans-serif",
                        fontWeight: 600,
                        fontSize: "1.8rem",
                        lineHeight: "2.7rem",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        color: "white",
                      }}
                    >
                      {data.user}
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{ display: "flex", gap: "4rem", alignItems: "center" }}
                >
                  <Box
                    sx={{
                      background: "#535C68",
                      borderRadius: "0.7rem",
                      color: "white",
                      padding: "0.5rem",
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: 400,
                      fontSize: "1.6rem",
                    }}
                  >
                    Lvl {data.level}
                  </Box>
                  <Box
                    sx={{
                      background:
                        data.friendshipstatus === "none"
                          ? "#686DE0"
                          : "#535C68",
                      padding: "0.8rem 2rem",
                      color: "white",
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: 400,
                      fontSize: "1.1rem",
                      borderRadius: "1.2rem",
                      display: "flex",
                      gap: "1rem",
                      alignItems: "center",
                      cursor:
                        data.friendshipstatus === "none"
                          ? "pointer"
                          : "default",
                    }}
                    onClick={() => {
                      if (data.friendshipstatus === "none") {
                        handleUsername(data.user);
                      }
                    }}
                  >
                    {data.friendshipstatus === "none" ? (
                      <>
                        Add <PersonAddIcon />
                      </>
                    ) : data.friendshipstatus === true ? (
                      "Friends"
                    ) : (
                      "Pending"
                    )}
                  </Box>
                </Box>
              </Box>
            ))
          ) : (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "50vh",
              }}
            >
              <Typography
                variant="h6"
                sx={{ textAlign: "center", color: "#BFBFBF" }}
              >
                No results found
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          borderRadius: "0.8rem",
          border: "1px solid #3F3F3F",
          margin: "1rem 0rem",
        }}
      >
        <Box
          sx={{
            background: "#686DE0",
            borderRadius: "0.8rem 0.8rem 0rem 0rem",
            padding: "0.4rem 3.4rem",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            sx={{ display: "flex", gap: "2rem", alignItems: "center" }}
            flex={1}
          >
            <Box
              sx={{
                fontFamily: "Poppins, sans-serif",
                fontWeight: 500,
                fontSize: { xs: "1.6rem", sm: "2.4rem", md: "3.2rem" },
                lineHeight: { xs: "2.4rem", sm: "3.6rem", md: "4.8rem" },
                color: "white",
              }}
            >
              Create Team
            </Box>
            <Box
              display="flex"
              border="0.5px solid #7F7F7F"
              backgroundColor={"#535C68"}
              borderRadius={2}
              flex={1}
              sx={{ height: { xs: 30, sm: 35, md: 35 } }}
            >
              <Box
                sx={{
                  position: "relative",
                  ml: 1,
                  flex: 1,
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
              >
                <InputBase
                  sx={{
                    flex: 1,
                    width: "100%",
                    color: "#BFBFBF",
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: 500,
                    fontSize: "1.8rem",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                  value={registerTeam}
                  onChange={(e) => setRegisterTeam(e.target.value)}
                />
                {!registerTeam && (
                  <Box
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      pointerEvents: "none",
                      color: "#BFBFBF",
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: 500,
                      fontSize: "1.8rem",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      alignContent: "center",
                    }}
                  >
                    Team Name...
                  </Box>
                )}
              </Box>
            </Box>

            <Box sx={{ alignContent: "center" }}>
              <InternalButton
                text="Create"
                type="submit"
                onClick={handleTeamName}
              />
            </Box>
          </Box>
        </Box>
        <Box sx={{ padding: "1rem" }}>
          {friendImages && friendImages.length > 0 ? (
            friendImages.map((data, idx) => (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "1rem",
                  justifyContent: "space-between",
                }}
                key={idx}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box
                    component="img"
                    src={`data:image/png;base64,${data.user_profile_picture}`}
                    alt="userImg"
                    sx={{
                      width: "6rem",
                      height: "6rem",
                      borderRadius: "50%",
                      objectFit: "cover",
                      display: "block",
                      aspectRatio: "1 / 1",
                    }}
                  />
                  <Box sx={{ marginLeft: "1rem" }}>
                    <Box
                      sx={{
                        fontFamily: "Poppins, sans-serif",
                        fontWeight: 600,
                        fontSize: "1.8rem",
                        lineHeight: "2.7rem",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        color: "white",
                      }}
                    >
                      {data.username}
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{ display: "flex", gap: "4rem", alignItems: "center" }}
                >
                  <Box
                    sx={{
                      background: "#535C68",
                      borderRadius: "0.7rem",
                      color: "white",
                      padding: "0.5rem",
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: 400,
                      fontSize: "1.6rem",
                    }}
                  >
                    Lvl {data.user_level}
                  </Box>
                  <Box
                    sx={{
                      background:
                        data.friendshipstatus === "none"
                          ? "#686DE0"
                          : "#535C68",
                      padding: "0.8rem 2rem",
                      color: "white",
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: 400,
                      fontSize: "1.1rem",
                      borderRadius: "1.2rem",
                      display: "flex",
                      gap: "1rem",
                      alignItems: "center",
                      cursor:
                        data.friendshipstatus === "none"
                          ? "pointer"
                          : "default",
                    }}
                    onClick={() => handleUser(data.username)}
                  >
                    {selectedFriends.includes(data.username) ? "Remove" : "Add"}
                    <PersonAddIcon />
                  </Box>
                </Box>
              </Box>
            ))
          ) : (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "50vh",
              }}
            >
              <Typography
                variant="h6"
                sx={{ textAlign: "center", color: "#BFBFBF" }}
              >
                No results found
              </Typography>
            </Box>
          )}
        </Box>
      </Box>

      {/* Snackbar */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default FriendAndGroups;
