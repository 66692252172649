import {
  Box,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import React, { useState } from "react";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import PersonAddIcon from "@mui/icons-material/PersonAdd";

function FriendsCard({
  title,
  allFriendsData,
  buttonType,
  onAcceptRequest,
  onRejectRequest,
  onRemoveFriend,
  imageSrc,
}) {
  const [open, setOpen] = useState(false);
  const [selectedFriend, setSelectedFriend] = useState(null);

  const handleClickOpen = (friend) => {
    setSelectedFriend(friend);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirmRemove = () => {
    onRemoveFriend(selectedFriend.username);
    setOpen(false);
  };

  return (
    <Box
      sx={{
        borderRadius: "0.8rem",
        border: "1px solid #3F3F3F",
        width: "100%",
        position: "relative",
      }}
    >
      <Box
        sx={{
          position: "absoulte",
          top: 0,
          background: "#686DE0",
          borderRadius: "0.8rem 0.8rem 0rem 0rem",
          fontFamily: "Poppins, sans-serif !important",
          fontWeight: 500,
          fontSize: "3.2rem",
          lineHeight: "4.8rem",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          color: "white",
          padding: "0rem 1rem",
          zIndex: 1,
        }}
      >
        {title}
      </Box>
      {allFriendsData?.length > 0 ? (
        <Box
          sx={{
            height: {
              xs: "30rem",
              sm: "30rem",
              md: "30rem",
            },
            overflowY: "auto",
            "&::-webkit-scrollbar": {
              width: 6,
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "grey",
              borderRadius: "10px",
            },
          }}
        >
          {allFriendsData.map((friend, idx) => (
            <Box
              key={idx}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "1rem 1rem",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box
                  component="img"
                  src={friend?.profilePicture}
                  alt="userImg"
                  sx={{
                    width: "6rem",
                    height: "6rem",
                    borderRadius: "50%",
                    objectFit: "cover",
                    display: "block",
                    aspectRatio: "1 / 1",
                  }}
                />
                <Box sx={{ marginLeft: "1rem" }}>
                  <Box
                    sx={{
                      fontFamily: "Poppins, sans-serif !important",
                      fontWeight: 600,
                      fontSize: "1.8rem",
                      lineHeight: "2.7rem",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      width: "100%",
                      color: "white",
                    }}
                  >
                    {friend?.username}
                  </Box>
                </Box>
              </Box>
              <Box sx={{ display: "flex", gap: "4rem", alignItems: "center" }}>
                <Box
                  sx={{
                    background: "#535C68",
                    borderRadius: "0.7rem",
                    color: "white",
                    padding: "0.5rem",
                    fontFamily: "Poppins, sans-serif !important",
                    fontWeight: 400,
                    fontSize: "1.6rem",
                    lineHeight: "2.4rem",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    width: "100%",
                  }}
                >
                  Lvl {friend?.user_level}
                </Box>
                {buttonType === "friendList" ? (
                  <Box
                    sx={{
                      background: "#686DE0",
                      padding: "0.8rem 2rem",
                      color: "white",
                      fontFamily: "Poppins, sans-serif !important",
                      fontWeight: 400,
                      fontSize: "1.1rem",
                      lineHeight: "1.6rem",
                      borderRadius: "1.2rem",
                      display: "flex",
                      gap: "1rem",
                      cursor: "pointer",
                    }}
                    onClick={() => handleClickOpen(friend)}
                  >
                    Remove <PersonRemoveIcon />
                  </Box>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "1.5rem",
                    }}
                  >
                    <Box
                      sx={{
                        background: "#686DE0",
                        padding: "0.8rem 2rem",
                        color: "white",
                        fontFamily: "Poppins, sans-serif !important",
                        fontWeight: 400,
                        fontSize: "1.1rem",
                        lineHeight: "1.6rem",
                        borderRadius: "1.2rem",
                        display: "flex",
                        gap: "1rem",
                        cursor: "pointer",
                      }}
                      onClick={() => onAcceptRequest(friend.username)}
                    >
                      Accept
                      <PersonAddIcon />
                    </Box>
                    <Box
                      sx={{
                        background: "#686DE0",
                        padding: "0.8rem 2rem",
                        color: "white",
                        borderRadius: "2rem",
                        cursor: "pointer",
                      }}
                      onClick={() => onRejectRequest(friend.username)}
                    >
                      <PersonRemoveIcon />
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
          ))}
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "50vh",
          }}
        >
          <Typography
            variant="h6"
            sx={{ textAlign: "center", color: "#BFBFBF" }}
          >
            No results found
          </Typography>
        </Box>
      )}

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Removal"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to remove {selectedFriend?.username}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            No
          </Button>
          <Button onClick={handleConfirmRemove} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default FriendsCard;
