import { Box, Grid, Card, CardContent, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import axios from "axios";

function RecentLive() {
  const [currentVideo, setCurrentVideo] = useState({
    url: "",
    poster: "",
    playing: true,
  });

  const [youTubeData, setYouTubeData] = useState([]);
  const YOUTUBE_API_KEY = `${process.env.REACT_APP_YOUTUBE_API_KEY}`;
  const basUrl = `${process.env.REACT_APP_CMS_PROTOCOL}${process.env.REACT_APP_CMS_HOST}${process.env.REACT_APP_CMS_PORT}`;

  useEffect(() => {
    const fetchYouTubeLinks = async () => {
      try {
        const response = await axios.get(`${basUrl}/api/youtube-links`);
        const links = response.data;

        // Fetch video details for each link
        const videoDetails = await Promise.all(
          links.map(async (item) => {
            const videoId = extractVideoId(item.link);
            if (videoId) {
              const details = await fetchVideoDetails(videoId);
              return { ...item, ...details };
            }
            return item;
          })
        );

        setYouTubeData(videoDetails);

        if (videoDetails.length > 0) {
          const firstVideo = videoDetails[0];
          // const thumbnailUrl = getThumbnailUrl(firstVideo.link);
          setCurrentVideo({
            url: `https://www.youtube.com/watch?v=${extractVideoId(
              firstVideo.link
            )}`,
            // poster: thumbnailUrl,
            playing: true,
          });
        }
      } catch (error) {
        console.error("Failed to fetch YouTube data:", error);
      }
    };

    fetchYouTubeLinks();
  }, []);

  const extractVideoId = (url) => {
    const regex =
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|v\/|embed\/|watch\?v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const match = url.match(regex);
    return match ? match[1] : null;
  };

  const getThumbnailUrl = (url) => {
    const videoId = extractVideoId(url);
    return videoId
      ? `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`
      : "";
  };

  const fetchVideoDetails = async (videoId) => {
    try {
      const response = await axios.get(
        `https://www.googleapis.com/youtube/v3/videos?id=${videoId}&key=${YOUTUBE_API_KEY}&part=snippet`
      );
      const videoData = response.data.items[0].snippet;
      return {
        link: `https://www.youtube.com/watch?v=${videoId}`,
        title: videoData.title,
        description: videoData.description,
        date: videoData.publishedAt,
      };
    } catch (error) {
      console.error(`Failed to fetch details for video ID ${videoId}:`, error);
      return null;
    }
  };

  const handleTileClick = (url) => {
    const videoId = extractVideoId(url);
    const thumbnailUrl = getThumbnailUrl(url);
    setCurrentVideo({
      url: videoId ? `https://www.youtube.com/watch?v=${videoId}` : "",
      poster: thumbnailUrl,
      playing: true,
    });
  };

  function formatTournamentDate(dateString) {
    const date = new Date(dateString);
    const options = {
      day: "2-digit",
      month: "short",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true, // To get AM/PM format
    };
    return date.toLocaleDateString("en-US", options).replace(",", "");
  }

  return (
    <Box
      sx={{
        height: "100%",
        overflowY: "auto",
        "&::-webkit-scrollbar": {
          width: 6,
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "grey",
          borderRadius: "10px",
        },
      }}
    >
      <Box sx={{ margin: "2rem" }}>
        <Box
          sx={{
            width: "100%",
            marginBottom: "2rem",
            height: "50rem",
            borderRadius: "2rem",
            overflow: "hidden",
          }}
        >
          <ReactPlayer
            url={currentVideo.url}
            playing={currentVideo.playing}
            controls
            width="100%"
            height="100%"
            style={{ borderRadius: "10rem" }}
            light={currentVideo.poster}
          />
        </Box>
        <Grid container spacing={2}>
          {youTubeData.map((item, index) => {
            const thumbnailUrl = getThumbnailUrl(item.link);

            return (
              <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                <Card
                  onClick={() => handleTileClick(item.link)}
                  sx={{
                    cursor: "pointer",
                    height: "90%",
                    display: "flex",
                    flexDirection: "column",
                    border: "1px solid #8B8B8B",
                    borderRadius: "1rem",
                    padding: "0.5rem",
                    margin: "0.5rem",
                    backgroundColor: "#171717",
                    color: "white",
                    textAlign: "left",
                    flex: "1 1 calc(23% - 1rem)",
                    minWidth: "22rem",
                  }}
                >
                  <Box
                    component="img"
                    src={thumbnailUrl}
                    alt={item.title}
                    sx={{
                      width: "100%",
                      height: { xs: "24rem", sm: "16rem", md: "13rem" },
                      objectFit: "cover",
                      borderRadius: "0.9rem",
                    }}
                  />

                  <CardContent>
                    <Box
                      sx={{
                        fontFamily: "Poppins, sans-serif !important",
                        fontWeight: 500,
                        fontSize: "0.8rem",
                        lineHeight: "1.2rem",
                      }}
                    >
                      {formatTournamentDate(item.date)}
                    </Box>
                    <Box
                      sx={{
                        fontFamily: "Poppins, sans-serif !important",
                        fontWeight: 600,
                        fontSize: { xs: "1rem", sm: "1.2rem", md: "1.2rem" },
                        lineHeight: {
                          xs: "1.5rem",
                          sm: "1.8rem",
                          md: "1.8rem",
                        },
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        width: "100%",
                      }}
                    >
                      {item.title}
                    </Box>
                    <Box
                      sx={{
                        fontFamily: "Poppins, sans-serif !important",
                        fontWeight: 500,
                        fontSize: { xs: "0.6rem", sm: "1rem", md: "1rem" },
                        lineHeight: {
                          xs: "0.9rem",
                          sm: "1.5rem",
                          md: "1.5rem",
                        },
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 2,
                        color: "#CBCCCC",
                      }}
                    >
                      {item.description}
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </Box>
  );
}

export default RecentLive;
