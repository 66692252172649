import React, { useState } from "react";
import {
  Box,
  IconButton,
  InputBase,
  Modal,
  Card,
  Divider,
} from "@mui/material";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import SearchIcon from "@mui/icons-material/Search";
import { useProSidebar } from "react-pro-sidebar";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import CardGiftcardOutlinedIcon from "@mui/icons-material/CardGiftcardOutlined";
import DiamondIcon from "@mui/icons-material/Diamond";
import axios from "axios";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { Link } from "react-router-dom";
import trophy from "../Assests/Dashboard/blog/trophy.svg";
import squad from "../Assests/Dashboard/blog/squad.svg";
import bgImage from "../Assests/Dashboard/bgdashboard.svg";

const Topbar = ({ userDetails }) => {
  const localPort = `${process.env.REACT_APP_CMS_PROTOCOL}${process.env.REACT_APP_CMS_HOST}${process.env.REACT_APP_PORT}`;
  const { toggleSidebar, broken, rtl } = useProSidebar();

  const UserCoins = userDetails?.user_coins;
  const UserXp = userDetails?.user_xp;
  const UserLevel = userDetails?.user_level;

  const [searchResults, setSearchResults] = useState([]);
  const [openModal, setOpenModal] = useState(false);

  const handleMasterSearch = async (keyword) => {
    try {
      const response = await axios.get(`${localPort}/master-search/`, {
        params: { keyword },
      });
      setSearchResults(response.data);
      setOpenModal(true);
    } catch (error) {
      console.error("Error fetching search results:", error);
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSearchResults([]);
  };

  function formatTournamentDate(dateString) {
    const date = new Date(dateString); // Parse the date string
    const options = {
      day: "2-digit",
      month: "short",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true, // To get AM/PM format
    };

    return date.toLocaleDateString("en-US", options).replace(",", "");
  }

  const formatWinPrice = (price) => {
    return price >= 1000 ? `${(price / 1000).toFixed(0)}k` : price;
  };

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      p={2.5}
      borderBottom="0.5px solid #454545"
      sx={{
        position: "sticky",
        top: 0,
        zIndex: 1000,
        background: `linear-gradient(to right, #262829, #393B3D)`,
      }}
    >
      {broken && !rtl && (
        <IconButton onClick={() => toggleSidebar()}>
          <MenuOutlinedIcon sx={{ color: "#BFBFBF", fontSize: "4rem" }} />
        </IconButton>
      )}
      <Box
        display="flex"
        border="0.5px solid #7F7F7F"
        backgroundColor={"#535C68"}
        p={0.2}
        borderRadius={2}
        flex={1}
        sx={{
          height: { xs: 30, sm: 40, md: 40 },
        }}
      >
        <IconButton type="button" sx={{ color: "#BFBFBF" }}>
          <SearchIcon />
        </IconButton>
        <InputBase
          sx={{
            ml: 1,
            flex: 1,
            color: "#BFBFBF",
            fontFamily: "Poppins, sans Sarif",
            fontWeight: 500,
            fontSize: "1.8rem",
          }}
          placeholder="Search..."
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleMasterSearch(e.target.value);
            }
          }}
        />
      </Box>

      <Modal open={openModal} onClose={handleCloseModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "#535C68",
            boxShadow: 24,
            margin: "0rem 2rem",
            height: "60%",
            width: "60%",
            borderRadius: "1.6rem",
            border: "none",
            outline: "none",
          }}
        >
          {searchResults && (
            <>
              {/* Render Tournament if available */}
              {searchResults.tournaments &&
                searchResults.tournaments.slice(0, 4).length > 0 && (
                  <Box sx={{ padding: "2rem" }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          color: "#EFEFEF",
                          fontFamily: "Poppins, sans-serif",
                          fontWeight: 700,
                          fontSize: {
                            xs: "1.6rem",
                            sm: "2.4rem",
                            md: "2.4rem",
                          },
                          lineHeight: {
                            xs: "2.4rem",
                            sm: "3.6rem",
                            md: "3.6rem",
                          },
                        }}
                      >
                        Tournaments
                      </Box>
                      <Link to="/tournaments">
                        <Box
                          sx={{ display: "flex", alignItems: "center", ml: 2 }}
                          onClick={() => setOpenModal(false)}
                        >
                          <Box
                            sx={{
                              bgcolor: "#383838",
                              borderRadius: "0.6rem",
                              fontFamily: "Poppins, sans-serif",
                              fontWeight: 400,
                              fontSize: {
                                xs: "0.8rem",
                                sm: "1.2rem",
                                md: "1.2rem",
                              },
                              lineHeight: {
                                xs: "1rem",
                                sm: "1.2rem",
                                md: "1.4rem",
                              },
                              color: "white",
                              padding: "0.5rem ",
                              display: "flex",
                              alignItems: "center",
                              cursor: "pointer",
                            }}
                          >
                            View All
                            <ArrowRightIcon
                              sx={{
                                color: "white",
                                height: "1.6rem",
                                width: "1.6rem",
                              }}
                            />
                          </Box>
                        </Box>
                      </Link>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "1rem",
                        flex: "1 1 calc(23% - 1rem)",
                        minWidth: "22rem",
                        maxWidth: "calc(23% - 1rem)",
                      }}
                    >
                      {searchResults.tournaments.map((tournament, index) => (
                        <Card
                          key={index}
                          sx={{
                            padding: "0.4rem",
                            backgroundColor: "#2F3132",
                            borderRadius: "0.8rem",
                            minWidth: { xs: "12rem", sm: "16rem", md: "20rem" },
                          }}
                        >
                          <Box
                            component="img"
                            src={tournament?.tournament_details_cms?.icon}
                            alt="gameImg"
                            sx={{
                              width: "100%",
                              height: {
                                xs: "9.4rem",
                                sm: "12rem",
                                md: "13rem",
                              },
                              objectFit: "cover",
                              borderRadius: "0.9rem",
                            }}
                          />
                          <Box
                            sx={{
                              fontFamily: "Poppins, sans-serif !important",
                              fontWeight: 500,
                              fontSize: {
                                xs: "1.4rem",
                                sm: "1.4rem",
                                md: "1rem",
                              },
                              lineHeight: {
                                xs: "2.1rem",
                                sm: "2.1rem",
                                md: "1.4rem",
                              },
                              color: "white",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {formatTournamentDate(tournament.upComingDate)}
                          </Box>
                          <Box
                            sx={{
                              fontFamily: "Poppins, sans-serif !important",
                              fontWeight: 600,
                              fontSize: {
                                xs: "2rem",
                                sm: "2rem",
                                md: "2rem",
                              },
                              lineHeight: {
                                xs: "3rem",
                                sm: "3rem",
                                md: "3rem",
                              },
                              color: "white",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              flexWrap: "wrap",
                            }}
                          >
                            {tournament?.tournament_details_cms?.title}
                          </Box>{" "}
                          <Box
                            sx={{
                              fontFamily: "Poppins, sans-serif !important",
                              fontWeight: 500,
                              fontSize: {
                                xs: "1.2rem",
                                sm: "1.2rem",
                                md: "1.4rem",
                              },
                              lineHeight: {
                                xs: "1.8rem",
                                sm: "1.8rem",
                                md: "2.1rem",
                              },
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              display: "-webkit-box",
                              WebkitBoxOrient: "vertical",
                              WebkitLineClamp: 2,
                              color: "#CBCBCC",
                            }}
                          >
                            {tournament?.tournament_details_cms?.description}
                          </Box>
                          <Divider
                            sx={{
                              my: 1,
                              backgroundColor: "#8B8B8B",
                              width: "100%",
                            }}
                          />
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-evenly",
                            }}
                          >
                            <Box
                              sx={{
                                alignItems: "center",
                              }}
                            >
                              <Box
                                sx={{
                                  fontFamily: "Poppins, sans-serif !important",
                                  fontWeight: 500,
                                  fontSize: {
                                    xs: "1rem",
                                    sm: "1.4rem",
                                    md: "1.2rem",
                                  },
                                  lineHeight: {
                                    xs: "1.6rem",
                                    sm: "2.1rem",
                                    md: "2rem",
                                  },
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  display: "-webkit-box",
                                  WebkitBoxOrient: "vertical",
                                  WebkitLineClamp: 2,

                                  color: "#CBCBCC",
                                }}
                              >
                                Win Price
                              </Box>
                              <Box sx={{ display: "flex", gap: "0.5rem" }}>
                                <Box
                                  component="img"
                                  src={trophy}
                                  alt="trophy"
                                  sx={{
                                    height: {
                                      xs: "1.6rem",
                                      sm: "1.6rem",
                                      md: "2rem",
                                    },
                                    width: {
                                      xs: "1.6rem",
                                      sm: "1.6rem",
                                      md: "2rem",
                                    },
                                  }}
                                />
                                <Box
                                  sx={{
                                    fontFamily:
                                      "Poppins, sans-serif !important",
                                    fontWeight: 700,
                                    fontSize: {
                                      xs: "1.2rem",
                                      sm: "2rem",
                                      md: "1.4rem",
                                    },
                                    lineHeight: {
                                      xs: "1.8rem",
                                      sm: "2.5rem",
                                      md: "2.1rem",
                                    },
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",

                                    color: "#CBCBCC",
                                  }}
                                >
                                  {formatWinPrice(
                                    tournament?.tournament_details_cms?.winPrice
                                  )}
                                </Box>
                              </Box>
                            </Box>
                            <Box
                              sx={{
                                alignItems: "center",
                              }}
                            >
                              <Box
                                sx={{
                                  fontFamily: "Poppins, sans-serif !important",
                                  fontWeight: 500,
                                  fontSize: {
                                    xs: "1rem",
                                    sm: "1.4rem",
                                    md: "1.2rem",
                                  },
                                  lineHeight: {
                                    xs: "1.6rem",
                                    sm: "2.1rem",
                                    md: "2rem",
                                  },
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  display: "-webkit-box",
                                  WebkitBoxOrient: "vertical",
                                  WebkitLineClamp: 2,

                                  color: "#CBCBCC",
                                }}
                              >
                                Player Slot
                              </Box>
                              <Box sx={{ display: "flex", gap: "0.5rem" }}>
                                <Box
                                  component="img"
                                  src={squad}
                                  alt="squad"
                                  sx={{
                                    height: {
                                      xs: "1.6rem",
                                      sm: "1.6rem",
                                      md: "2rem",
                                    },
                                    width: {
                                      xs: "1.6rem",
                                      sm: "1.6rem",
                                      md: "2rem",
                                    },
                                  }}
                                />

                                <Box
                                  sx={{
                                    fontFamily:
                                      "Poppins, sans-serif !important",
                                    fontWeight: 700,
                                    fontSize: {
                                      xs: "1.2rem",
                                      sm: "2rem",
                                      md: "1.4rem",
                                    },
                                    lineHeight: {
                                      xs: "1.8rem",
                                      sm: "2.5rem",
                                      md: "2.1rem",
                                    },
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",

                                    color: "#CBCBCC",
                                  }}
                                >
                                  {
                                    tournament?.tournament_details_cms
                                      ?.playerSlot
                                  }
                                  v
                                  {
                                    tournament?.tournament_details_cms
                                      ?.playerSlot
                                  }
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                          {/* <CardContent>
                          <Typography variant="h5" component="div">
                            {tournament.tournament_name}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            Date: {tournament.tournament_date}
                          </Typography>
                        </CardContent> */}
                        </Card>
                      ))}
                    </Box>
                  </Box>
                )}

              {searchResults.blogs &&
                searchResults.blogs.slice(0, 4).length > 0 && (
                  <Box sx={{ padding: "2rem" }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          color: "#EFEFEF",
                          fontFamily: "Poppins, sans-serif",
                          fontWeight: 700,
                          fontSize: {
                            xs: "1.6rem",
                            sm: "2.4rem",
                            md: "2.4rem",
                          },
                          lineHeight: {
                            xs: "2.4rem",
                            sm: "3.6rem",
                            md: "3.6rem",
                          },
                        }}
                      >
                        Blogs
                      </Box>
                      <Box
                        sx={{ display: "flex", alignItems: "center", ml: 2 }}
                      >
                        <Link to="/community">
                          {" "}
                          <Box
                            sx={{
                              bgcolor: "#383838",
                              borderRadius: "0.6rem",
                              fontFamily: "Poppins, sans-serif",
                              fontWeight: 400,
                              fontSize: {
                                xs: "0.8rem",
                                sm: "1.2rem",
                                md: "1.2rem",
                              },
                              lineHeight: {
                                xs: "1rem",
                                sm: "1.2rem",
                                md: "1.4rem",
                              },
                              color: "white",
                              padding: "0.5rem ",
                              display: "flex",
                              alignItems: "center",
                              cursor: "pointer",
                            }}
                            onClick={() => setOpenModal(false)}
                          >
                            View All
                            <ArrowRightIcon
                              sx={{
                                color: "white",
                                height: "1.6rem",
                                width: "1.6rem",
                              }}
                            />
                          </Box>
                        </Link>
                      </Box>
                    </Box>

                    {searchResults.blogs.map((blogData, index) => (
                      <Box
                        key={index}
                        sx={{
                          display: "flex",
                          gap: "1rem",
                          background: "#232526",
                          padding: "0.5rem",
                          borderRadius: "1rem",
                          backgroundImage: `url(${bgImage})`,
                        }}
                      >
                        <Box
                          sx={{
                            flex: 1,
                            width: {
                              xs: "15.4rem",
                              sm: "17.6rem",
                              md: "28.2rem",
                            },
                            height: { xs: "8.6rem", sm: "10rem", md: "16rem" },
                            borderRadius: "0.9rem",
                            objectFit: "cover",
                          }}
                          component="img"
                          src={blogData?.image}
                          alt="blogs"
                        />
                        <Box
                          sx={{
                            flex: 2,
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Box
                            sx={{
                              fontFamily: "Poppins, sans-serif",
                              fontWeight: 400,
                              fontSize: {
                                xs: "1.2rem",
                                sm: "2rem",
                                md: "2.4rem",
                              },
                              lineHeight: {
                                xs: "1.6rem",
                                sm: "2.2rem",
                                md: "2.8rem",
                              },
                              color: "white",
                            }}
                          >
                            {blogData.title}
                          </Box>
                          <br />
                          <Box
                            sx={{
                              fontFamily: "Poppins, sans-serif",
                              fontWeight: 500,
                              fontSize: {
                                xs: "0.4rem",
                                sm: "0.8rem",
                                md: "1.2rem",
                              },
                              lineHeight: {
                                xs: "0.6rem",
                                sm: "1.2rem",
                                md: "1.8rem",
                              },
                              color: "#F6F6F6",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              display: "-webkit-box",
                              WebkitLineClamp: 4,
                              WebkitBoxOrient: "vertical",
                              wordWrap: "break-word",
                              whiteSpace: "normal",
                            }}
                            dangerouslySetInnerHTML={{
                              __html: blogData.content,
                            }}
                          />
                        </Box>
                      </Box>
                    ))}
                  </Box>
                )}
            </>
          )}
          {/* Show No Results if all arrays are empty */}
          {searchResults &&
            (!searchResults.tournaments ||
              searchResults.tournaments.length === 0) &&
            (!searchResults.blogs || searchResults.blogs.length === 0) &&
            (!searchResults.tournamentGroups ||
              searchResults.tournamentGroups.length === 0) && (
              <Box
                sx={{
                  display: "flex", // Enable Flexbox
                  justifyContent: "center", // Horizontal centering
                  alignItems: "center", // Vertical centering
                  height: "100%", // Ensures the box takes full height of the parent
                }}
              >
                <Box
                  sx={{
                    color: "#EFEFEF",
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: 700,
                    fontSize: { xs: "0.8rem", sm: "1.4rem", md: "1.6rem" },
                    lineHeight: {
                      xs: "1.2rem",
                      sm: "2.1rem",
                      md: "2.4rem",
                    },
                    textAlign: "center", // Center-align the text
                  }}
                >
                  No results found
                </Box>
              </Box>
            )}
        </Box>
      </Modal>

      <Box sx={{ display: "flex", justifyContent: "space-evenly", flex: 1 }}>
        <Box display="flex" alignItems="center">
          <Box
            position="relative"
            display="inline-flex"
            sx={{ height: "4rem", width: "4rem" }}
          >
            <CircularProgressbar
              value={100}
              styles={buildStyles({
                textColor: "#fff",
                pathColor: "#7F00FF",
                trailColor: "black",
              })}
            />
            <Box
              position="absolute"
              top="50%"
              left="50%"
              sx={{
                transform: "translate(-50%, -50%)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "white",
              }}
            >
              <DiamondIcon style={{ fontSize: "1.5rem" }} />
            </Box>
          </Box>
          <Box ml={1}>
            <Box
              sx={{
                color: "white",
                fontFamily: "Poppins, sans-serif !important",
                fontWeight: 500,
                fontSize: "1.6rem",
                lineHeight: "2.3rem",
              }}
            >
              BSP
            </Box>
            <Box
              sx={{
                color: "white",
                fontFamily: "Poppins, sans-serif !important",
                fontWeight: 500,
                fontSize: "1.6rem",
                lineHeight: "2.3rem",
              }}
            >
              {UserCoins}
            </Box>
          </Box>
        </Box>
        <Box display="flex" alignItems="center">
          <Box
            position="relative"
            display="inline-flex"
            sx={{ height: "4rem", width: "4rem" }}
          >
            <CircularProgressbar
              maxValue={1000}
              value={UserXp}
              styles={buildStyles({
                textColor: "#fff",
                pathColor: "#7F00FF",
                trailColor: "black",
              })}
            />
            <Box
              position="absolute"
              top="50%"
              left="50%"
              sx={{
                transform: "translate(-50%, -50%)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "white",
              }}
            >
              <CardGiftcardOutlinedIcon style={{ fontSize: "1.5rem" }} />
            </Box>
          </Box>
          <Box ml={1}>
            <Box
              sx={{
                color: "white",
                fontFamily: "Poppins, sans-serif !important",
                fontWeight: 500,
                fontSize: "1.6rem",
                lineHeight: "2.3rem",
              }}
            >
              Lvl {UserLevel}
            </Box>
            <Box
              sx={{
                color: "#999A9B",
                fontFamily: "Poppins, sans-serif !important",
                fontWeight: 300,
                fontSize: "1.1rem",
                lineHeight: "1.6rem",
              }}
            >
              EXP {UserXp}
            </Box>
          </Box>
        </Box>
        {/* <Box display="flex" alignItems="center">
          <Box position="relative" display="inline-flex">
            <NotificationsIcon sx={{ height: "4rem", width: "4rem" }} />
          </Box>
        </Box> */}
      </Box>
    </Box>
  );
};

export default Topbar;
