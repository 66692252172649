import { Box } from "@mui/material";
import React, { useState, useEffect } from "react";
import Header from "./component/Header";

function Layout({ children }) {
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const maxOpacity = 0.9
  const headerOpacity = Math.min(scrollPosition / 200, maxOpacity);

  return (
    <React.Fragment>
      <Box
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          zIndex: 9999,
          backgroundColor: `rgba(0, 0, 0, ${headerOpacity})`,
          transition: "background-color 0.3s",
        }}
      >
        <Header />
      </Box>
      <Box>{children}</Box>
    </React.Fragment>
  );
}

export default Layout;
