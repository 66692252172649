import React, { useEffect, useState } from "react";
import { Box, Snackbar, Alert } from "@mui/material";
import {
  CustomInputWithFormLabel,
  CustomInputWithoutIcon,
} from "../shared/CustomInput/CustomInput";
import CustomInput from "../shared/CustomInput/CustomInput";
import CustomPassword from "../shared/CustomPassword/CustomPassword";
import SignUpPage from "../Assests/HomeBackground/SignUpPage.svg";
import Button from "../shared/Button/Button";
import { Link, useNavigate } from "react-router-dom";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import axios from "axios";
import ImageUploader from "../shared/ImageUploader/ImageUploader ";
import CustomDropDownwithLabel from "../shared/CustomDropDown/CustomDropDown";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../redux/features/login/loginSlice";

const styles = {
  container: {
    width: "100%",
    minHeight: "100vh",
    height: "100%",
    backgroundImage: `url(${SignUpPage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  formContainer: {
    margin: "2rem",
    width: "fit-content",
    background: "#F3F7EC",
    borderRadius: "2.2rem",
    border: "0.2rem solid #36769D",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "2rem 4rem",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    overflow: "hidden",
  },
  title: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Poppins, sans-serif",
    fontWeight: 700,
    fontSize: { xs: "2.4rem", sm: "4.8rem", md: "4.8rem" },
    lineHeight: { xs: "3.6rem", sm: "4.8rem", md: "7.2rem" },
    textAlign: "center",
    height: "100%",
  },
  footerText: {
    fontFamily: "Poppins, sans-serif",
    fontWeight: 400,
    fontSize: "1.6rem",
    lineHeight: "2.4rem",
    color: "#3B3755",
  },
};

function SignUp() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const baseUrl = `${process.env.REACT_APP_PROTOCOL}${process.env.REACT_APP_HOST}${process.env.REACT_APP_PORT}`;

  const [formValues, setFormValues] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    confirmPassword: "",
    username: "",
    user_state: "",
    user_profile_picture: null,
  });
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const [showProfileSetup, setShowProfileSetup] = useState(false);
  const [selectedState, setSelectedState] = useState("");
  const [LoginData, setLoginData] = useState("");

  const { user, error } = useSelector((state) => state.login);

  useEffect(() => {
    if (formValues) {
      setLoginData((prevValues) => ({
        ...prevValues,
        email: formValues.email,
        password: formValues.password,
      }));
    }
  }, [formValues]);

  const statesArray = [
    "Andaman and Nicobar Islands",
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chandigarh",
    "Chhattisgarh",
    "Dadra and Nagar Haveli",
    "Daman and Diu",
    "Delhi",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jammu and Kashmir",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Ladakh",
    "Lakshadweep",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Puducherry",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
  ];

  const handleCloseSnackbar = () => setSnackbar({ ...snackbar, open: false });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: name === "email" ? value.toLowerCase() : value,
    }));
  };

  const validateForm = () => {
    const { first_name, last_name, email, password, confirmPassword } =
      formValues;

    if (!first_name || !last_name || !email || !password || !confirmPassword) {
      return "All fields are required.";
    }

    if (password !== confirmPassword) {
      return "Passwords do not match.";
    }

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) {
      return "Invalid email format.";
    }

    return null;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationError = validateForm();
    if (validationError) {
      setSnackbar({ open: true, message: validationError, severity: "error" });
      return;
    }

    const formData = new FormData();

    Object.keys(formValues).forEach((key) => {
      if (formValues[key]) {
        formData.append(key, formValues[key]);
      }
    });

    try {
      const response = await axios.post(`${baseUrl}/signUp`, formData);

      console.log(response);

      if (response) {
        dispatch(loginUser(LoginData));
      }

      setSnackbar({
        open: true,
        message: "Sign up successful!",
        severity: "success",
      });
    } catch (error) {
      console.error("Unable to send data to server", error);
      setSnackbar({
        open: true,
        message: error.response.data.message,
        severity: "error",
      });
    }
  };

  useEffect(() => {
    if (user) {
      navigate("/dashboard");
    }
  }, [user, error, navigate]);

  const handleShowProfileSetup = (e) => {
    e.preventDefault();

    const validationError = validateForm();
    if (validationError) {
      setSnackbar({ open: true, message: validationError, severity: "error" });
      return;
    }

    setShowProfileSetup(true);
  };

  const handleImageUpload = (imageFile) => {
    const MAX_SIZE = 55 * 1024;

    if (imageFile && imageFile.size > MAX_SIZE) {
      return;
    }

    setFormValues({
      ...formValues,
      user_profile_picture: imageFile,
    });
  };

  return (
    <Box sx={styles.container}>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          severity={snackbar.severity}
          variant="filled"
          onClose={handleCloseSnackbar}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>

      {showProfileSetup ? (
        <Box sx={styles.formContainer}>
          <Box sx={{ display: "flex", width: "100%" }}>
            <Box
              sx={{
                width: "5rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={() => setShowProfileSetup(false)}
            >
              <KeyboardDoubleArrowLeftIcon
                sx={{
                  width: "5rem",
                  height: { xs: "2.5rem", sm: "4.9rem" },
                  color: "#423F63",
                }}
              />
            </Box>
            <Box sx={{ margin: "0 auto", ...styles.title }}>Profile Setup</Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              gap: "2rem",
            }}
          >
            <Box sx={{ order: { xs: 2, md: 1 }, mt: 4 }}>
              <form onSubmit={handleSubmit} style={{ width: "100%" }}>
                <CustomInputWithFormLabel
                  label="Username*"
                  name="username"
                  value={formValues.username}
                  onChange={handleChange}
                  required
                  placeholder="Enter username"
                />
                <CustomDropDownwithLabel
                  label="State*"
                  name="user_state"
                  value={formValues.user_state}
                  onChange={handleChange}
                  required
                  options={statesArray}
                  selectedState={selectedState}
                  setSelectedState={setSelectedState}
                />
                <Button text="Continue" type="submit" width="60%" />
              </form>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                order: { xs: 1, md: 2 },
              }}
            >
              <ImageUploader
                onImageUpload={handleImageUpload}
                maxSize={55 * 1024}
              />
            </Box>
          </Box>
        </Box>
      ) : (
        <Box sx={styles.formContainer}>
          <Box sx={{ display: "flex", width: "100%" }}>
            <Box
              sx={{
                width: "5rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Link to="/">
                <KeyboardDoubleArrowLeftIcon
                  sx={{
                    width: "5rem",
                    height: { xs: "2.5rem", sm: "4.9rem" },
                    color: "#423F63",
                  }}
                />
              </Link>
            </Box>
            <Box sx={{ margin: "0 auto", ...styles.title }}>Sign Up</Box>
          </Box>

          <form onSubmit={handleShowProfileSetup} style={{ width: "100%" }}>
            <Box sx={{ display: "flex", gap: "1rem" }}>
              <CustomInputWithoutIcon
                name="first_name"
                value={formValues.first_name}
                onChange={handleChange}
                placeholder="First name"
              />
              <CustomInputWithoutIcon
                name="last_name"
                value={formValues.last_name}
                onChange={handleChange}
                placeholder="Last name"
              />
            </Box>
            <CustomInput
              name="email"
              type="email"
              value={formValues.email}
              onChange={handleChange}
              placeholder="Enter email"
            />
            <CustomPassword
              label="Password"
              name="password"
              value={formValues.password}
              onChange={handleChange}
              placeholder="Enter password"
            />
            <CustomPassword
              label="Confirm Password"
              name="confirmPassword"
              value={formValues.confirmPassword}
              onChange={handleChange}
              placeholder="Confirm password"
            />
            <Button text="Continue" type="submit" width="60%" />
          </form>

          <Box sx={{ mt: 2, ...styles.footerText }}>
            Already have an account?{" "}
            <Link
              to="/login"
              style={{ textDecoration: "none", color: "#3B3755" }}
            >
              <span style={{ fontWeight: 700 }}>Login</span>
            </Link>
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default SignUp;
