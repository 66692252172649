import React, { useEffect, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import UserImage from "../../Assests/Image/Team_Icon.svg"; // Ensure you have a demo image here

import EastIcon from "@mui/icons-material/East";
import WestIcon from "@mui/icons-material/West";

import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Pagination, Navigation } from "swiper/modules";
import { Box, IconButton } from "@mui/material";

const buttonStyle = {
  position: "absolute",
  top: "50%",
  transform: "translateY(-50%)",
  zIndex: 10,
  backgroundColor: "rgba(0,0,0,0.5)",
  color: "white",
  border: "none",
  borderRadius: "50%",
  width: { xs: "2rem", sm: "2.5rem", md: "3rem" },
  height: { xs: "2rem", sm: "2.5rem", md: "3rem" },
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
};

function CustomHallOfFame({ items }) {
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const swiperRef = useRef(null);

  useEffect(() => {
    if (swiperRef.current) {
      const swiperInstance = swiperRef.current.swiper;
      swiperInstance.params.navigation.prevEl = prevRef.current;
      swiperInstance.params.navigation.nextEl = nextRef.current;

      swiperInstance.navigation.destroy();
      swiperInstance.navigation.init();
      swiperInstance.navigation.update();
    }
  }, []);

  return (
    <>
      {items.length > 0 ? (
        <Box
          sx={{
            display: { xs: "none", sm: "flex" },
            flexWrap: "wrap",
            gap: "6rem",
            justifyContent: "center",
          }}
        >
          {items.map((data, idx) =>
            data.teamUsernames.map((player, playerIdx) => (
              <Box
                key={`${idx}-${playerIdx}`}
                sx={{
                  background: "#323232A6",
                  width: "calc(50% - 3rem)",
                  borderRadius: "1rem",
                  overflow: "hidden",
                  flex: "0 0 auto",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box
                    component="img"
                    src={
                      player?.image
                        ? `data:image/png;base64,${player?.image}`
                        : UserImage
                    }
                    alt={player.username}
                    sx={{
                      width: { xs: "50%", sm: "50%", md: "50%" },
                      height: { xs: "15rem", sm: "20rem", md: "20rem" },
                      objectFit: "cover",
                    }}
                  />

                  <Box sx={{ padding: "1rem" }}>
                    <Box
                      sx={{
                        color: "#F3F7EC",
                        fontFamily: "Poppins , sans-serif",
                        fontWeight: 600,
                        fontSize: "2.4rem",
                        lineHeight: "3.6rem",
                        textAlign: "left",
                      }}
                    >
                      {player.username}
                    </Box>
                    <Box
                      sx={{
                        color: "#757575",
                        fontFamily: "Rubik Wet Paint, sans-serif",
                        fontWeight: 400,
                        fontSize: "1rem",
                        lineHeight: "1.2rem",
                        textAlign: "left",
                      }}
                    >
                      {data.gameTitle.toUpperCase()}{" "}
                    </Box>
                    <Box
                      sx={{
                        color: "#F0F0F0",
                        fontFamily: "Poppins , sans-serif",
                        fontWeight: 400,
                        fontSize: "0.8rem",
                        lineHeight: "1.2rem",
                        textAlign: "left",
                      }}
                    >
                      DESCRIPTION: {data.gameTitle.toUpperCase()}
                    </Box>
                  </Box>
                </Box>
              </Box>
            ))
          )}
        </Box>
      ) : (
        <Box
          sx={{
            margin: "auto",
            display: { xs: "none", sm: "flex" },
            justifyContent: "center",
            color: "#EFEFEF",
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: {
              xs: "1.6rem",
              sm: "2.4rem",
              md: "2.4rem",
            },
            lineHeight: {
              xs: "2.4rem",
              sm: "3.6rem",
              md: "3.6rem",
            },
          }}
        >
          Awaiting our inaugural champions
        </Box>
      )}

      {items.length > 0 ? (
        <Box
          sx={{
            display: { xs: "block", sm: "none" },
            flexWrap: "wrap",
            position: "relative",
            width: { xs: "90%", sm: "80%" },
            margin: "2rem auto",
          }}
        >
          <IconButton
            ref={prevRef}
            sx={{
              ...buttonStyle,
              left: { xs: "-20px", sm: "-30px", md: "-50px" },
            }}
          >
            <WestIcon />
          </IconButton>
          <IconButton
            ref={nextRef}
            sx={{
              ...buttonStyle,
              right: { xs: "-20px", sm: "-30px", md: "-50px" },
            }}
          >
            <EastIcon />
          </IconButton>
          <Swiper
            ref={swiperRef}
            slidesPerView={1}
            pagination={false}
            navigation={{
              prevEl: prevRef.current,
              nextEl: nextRef.current,
            }}
            modules={[Pagination, Navigation]}
            className="mySwiper"
          >
            {items.map((data, idx) =>
              data?.teamUsernames.map((player, playerIdx) => (
                <SwiperSlide key={`${idx}-${playerIdx}`}>
                  <Box
                    sx={{
                      background: "#323232A6",
                      borderRadius: "1rem",
                    }}
                  >
                    <Box sx={{ display: "flex" }}>
                      <Box sx={{ width: "50%", height: "100%" }}>
                        <img
                          src={
                            player?.image
                              ? `data:image/png;base64,${player?.image}`
                              : UserImage
                          }
                          alt={player.item}
                          style={{
                            objectFit: "cover",
                            height: "14rem",
                            width: "100%",
                            borderRadius: "1rem 0rem 0rem 1rem",
                          }}
                        />
                      </Box>
                      <Box
                        sx={{
                          display: { xs: "block", sm: "none" },
                          padding: "1rem",
                          width: "50%",
                          height: "100%",
                        }}
                      >
                        <Box
                          sx={{
                            color: "#F3F7EC",
                            fontFamily: "Poppins , sans-serif",
                            fontWeight: 600,
                            fontSize: "2.4rem",
                            lineHeight: "3.6rem",
                            textAlign: "left",
                          }}
                        >
                          {player.username} {/* Display each player's name */}
                        </Box>
                        <Box
                          sx={{
                            color: "#757575",
                            fontFamily: "Rubik Wet Paint, sans-serif",
                            fontWeight: 400,
                            fontSize: "1rem",
                            lineHeight: "1.2rem",
                            textAlign: "left",
                          }}
                        >
                          {data.gameTitle.toUpperCase()}{" "}
                          {/* Same game title for each player */}
                        </Box>
                        <Box
                          sx={{
                            color: "#F0F0F0",
                            fontFamily: "Poppins , sans-serif",
                            fontWeight: 400,
                            fontSize: "0.8rem",
                            lineHeight: "1.2rem",
                            textAlign: "left",
                          }}
                        >
                          DESCRIPTION: {data.gameTitle.toUpperCase()}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </SwiperSlide>
              ))
            )}
          </Swiper>
        </Box>
      ) : (
        <Box
          sx={{
            margin: " 1rem auto",
            display: { xs: "flex", sm: "none" },
            justifyContent: "center",
            color: "#EFEFEF",
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: {
              xs: "1.6rem",
              sm: "2.4rem",
              md: "2.4rem",
            },
            lineHeight: {
              xs: "2.4rem",
              sm: "3.6rem",
              md: "3.6rem",
            },
          }}
        >
          Awaiting our inaugural champions
        </Box>
      )}
    </>
  );
}

export default CustomHallOfFame;
