import { Box } from "@mui/material";
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import { Link } from "react-router-dom";

function MainPageCrousle({ data }) {
  return (
    <Box
      sx={{
        flexWrap: "wrap",
        margin: "auto",
      }}
    >
      <Swiper
        className="mySwiper"
        style={{ width: "auto", height: "auto" }}
        slidesPerView="auto"
        spaceBetween={10}
        pagination={true}
        modules={[Pagination]}
      >
        {data &&
          data.map((blogData, idx) => (
            <SwiperSlide key={idx}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", sm: "column", md: "row" },
                  borderRadius: "1.2rem",
                  overflow: "hidden",
                  background:
                    "linear-gradient(to bottom, #3A3C3E 0%, #303133 50%, #232526 100%)",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                }}
              >
                <Box
                  sx={{
                    width: { xs: "100%", sm: "100%", md: "40%" },
                    order: { xs: 2, sm: 2, md: 1 },
                    padding: "0.8rem",
                    position: "relative",
                    minHeight: "12rem",
                  }}
                >
                  <Box sx={{ display: "flex", gap: "1rem" }}>
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: "0.5rem",
                        marginTop: "0.5rem",
                      }}
                    >
                      {/* Mapping over the tags array */}
                      {blogData.tags &&
                        blogData.tags.slice(0, 5).map((tag, index) => (
                          <Box
                            key={index}
                            sx={{
                              color: "white",
                              background: "#535C68",
                              borderRadius: "0.7rem",
                              padding: "0.5rem 1rem",
                              alignItems: "center",
                              fontFamily: "PostNoBillsJaffna",
                              fontWeight: 800,
                              fontSize: {
                                xs: "1rem",
                                sm: "1.4rem",
                                md: "1.4rem",
                              },
                              lineHeight: {
                                xs: "1rem",
                                sm: "1.4rem",
                                md: "1.4rem",
                              },
                            }}
                          >
                            {tag}
                          </Box>
                        ))}
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      color: "white",
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: 700,
                      fontSize: { xs: "1.6rem", sm: "2.4rem", md: "2.4rem" },
                      lineHeight: { xs: "2.4rem", sm: "3.6rem", md: "3.6rem" },
                      margin: "0.5rem 0rem",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "-webkit-box",
                      WebkitLineClamp: 1,
                      WebkitBoxOrient: "vertical",
                      wordWrap: "break-word",
                      whiteSpace: "normal",
                    }}
                  >
                    {blogData.title}
                  </Box>
                  <Box
                    sx={{
                      color: "white",
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: { xs: 300, sm: 300, md: 400 },
                      fontSize: { xs: "0.8rem", sm: "1rem", md: "1.2rem" },
                      lineHeight: { xs: "0.8rem", sm: "1rem", md: "1.2rem" },
                      marginTop: { xs: "0.7rem", sm: "1.8rem", md: "1.2rem" },
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "-webkit-box",
                      WebkitLineClamp: { xs: "4", sm: "6", md: "10" },
                      WebkitBoxOrient: "vertical",
                      whiteSpace: "normal",
                    }}
                    dangerouslySetInnerHTML={{ __html: blogData.content }}
                  />
                  <Box
                    sx={{
                      display: { xs: "none", sm: "flex", md: "flex" },
                      color: "white",
                      justifyContent: "right",
                      position: "absolute",
                      bottom: 0,
                      width: "calc(100% - 1.6rem)",
                      margin: "0rem 0rem 1rem 0rem",
                    }}
                  >
                    <Link
                      to={{
                        pathname: "/community",
                      }}
                    >
                      <Box
                        sx={{
                          fontFamily: "Poppins, sans-serif",
                          fontWeight: 400,
                          fontSize: { xs: "1rem", sm: "1.2rem", md: "1.2rem" },
                          lineHeight: {
                            xs: "1rem",
                            sm: "1.2rem",
                            md: "1.2rem",
                          },
                          background: "#535C68",
                          borderRadius: "2rem",
                          padding: "0.56rem",
                          color: "white",
                        }}
                      >
                        {" "}
                        Read Full Blog
                      </Box>
                    </Link>
                  </Box>
                </Box>

                <Box
                  sx={{
                    width: { xs: "100%", sm: "100%", md: "60%" },
                    height: { xs: "40%", sm: "40%", md: "auto" },
                    order: { xs: 1, sm: 1, md: 2 },
                  }}
                >
                  <img
                    src={blogData.image}
                    alt="poster"
                    style={{
                      objectFit: "cover",
                      width: "100%",
                      height: "30rem",
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    display: { xs: "flex", sm: "none", md: "none" },
                    color: "white",
                    justifyContent: "right",
                    position: "absolute",
                    bottom: 0,
                    width: "calc(100% - 1.6rem)",
                    margin: "0rem 0rem 1rem 0rem",
                  }}
                >
                  <Link to="/community">
                    <Box
                      sx={{
                        fontFamily: "Poppins, sans-serif",
                        fontWeight: 400,
                        fontSize: { xs: "1rem", sm: "1.2rem", md: "1.2rem" },
                        lineHeight: {
                          xs: "1rem",
                          sm: "1.2rem",
                          md: "1.2rem",
                        },
                        background: "#535C68",
                        borderRadius: "2rem",
                        padding: "0.56rem",
                        color: "white",
                      }}
                    >
                      {" "}
                      Read Full Blog
                    </Box>
                  </Link>
                </Box>
              </Box>
            </SwiperSlide>
          ))}
      </Swiper>
    </Box>
  );
}

export default MainPageCrousle;
