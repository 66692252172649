import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import bgImage from "../Assests/Dashboard/bgdashboard.svg";
import {
  MyProSidebarProvider,
  useSidebarContext,
} from "../pages/global/sidebar/sidebarContext";
import Topbar from "../component/Topbar";
import UserDashboard from "../pages/UserDashboard";
import History from "../pages/History";
import RecentLive from "../pages/Recent&Live";
import HallOfFame from "../pages/HallOfFame";
import Friends from "../pages/Friends";
import Tournaments from "../pages/Tournaments";
import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { dashboard } from "../redux/features/dashboard/dashboardSlice";
import Profile from "../pages/Profile";
import Community from "../pages/Community";
import { profileImage } from "../redux/features/dashboard/profile_imageSlice";
import Support from "../pages/Support";
import ErrorPage from "../pages/ErrorPage";
import AboutUs from "../pages/AboutUs";

const DashboardContent = () => {
  const dispatch = useDispatch();
  const { isFirstSidebarCollapsed } = useSidebarContext();
  const { user } = useSelector((state) => state?.dashboard);

  useEffect(() => {
    dispatch(dashboard());
  }, [dispatch]);

  const username = user?.username;

  useEffect(() => {
    if (username) {
      dispatch(profileImage(username));
    }
  }, [dispatch, username]);

  return (
    <Box
      sx={{
        height: "100vh",
        width: "100%",
        display: "flex",
        flexDirection: { sm: "column", md: "row" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
        }}
      >
        <Topbar userDetails={user} sx={{ flex: "0 0 auto" }} />
        <Box
          sx={{
            height: "100%",
            flex: 1,  
            overflowY: "auto",
            backgroundImage:
              "linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0))",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <Routes>
            <Route path="/dashboard" element={<UserDashboard />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/history" element={<History />} />
            <Route path="/recent&Livestreams" element={<RecentLive />} />
            <Route path="/friends" element={<Friends />} />
            <Route path="/halloffame" element={<HallOfFame />} />
            <Route path="/tournaments" element={<Tournaments />} />
            <Route path="/community" element={<Community />} />
            <Route path="/support" element={<Support />} />
            <Route path="/aboutUs" element={<AboutUs />} />
            <Route path="*" element={<ErrorPage />} />
          </Routes>
        </Box>
      </Box>
      <Box
        sx={{
          display: { xs: "none", md: "block" },
        }}
      >
        <Friends collapsed={!isFirstSidebarCollapsed} userDetails={user} />
      </Box>
    </Box>
  );
};

function Dashboard() {
  return (
    <Box
      sx={{
        backgroundImage: `url(${bgImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <MyProSidebarProvider>
        <DashboardContent />
      </MyProSidebarProvider>
    </Box>
  );
}

export default Dashboard;
